$hamburger-menu-color: #212121;
$hamburger-menu-border-radius: 0;
$hamburger-menu-item-padding: .25rem 2rem;
$hamburger-menu-font-size: $font-size-md;
@mixin hamburger-menu-border {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    border: 1px solid $bon-jour-light;
    border-radius: $hamburger-menu-border-radius;
}

.hamburger-menu {
    z-index: 1000;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: absolute;
    top: 10px; //the positioning should be fixed according to the actual header implementation
    right: 10px;

    &-icon {
        padding: 10px;
        display: inline-block;
        cursor: pointer;
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        background: none;
        
        &-container {
            text-align: right;
            line-height: 0;
        }
    
        span {
            width: 24px;
            height: 1px;
            background-color: $mine-shaft;
            display: block;
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0px;
            }
        }

     &-active{
          border: 1px solid $bon-jour-light;
     }
    }
    &-content {
        position: relative;
        display: none;
        @include hamburger-menu-border;
        min-width: 300px;
        padding: .4rem;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        
        &-contact {
            font-size: 0.875rem;
            font-style: italic;
            padding: 6px 16px;
            border-radius: 0.25rem;
            background-color: rgba(0, 0, 0, 0.05);
        }

        &-item {
            display: block;
            padding: $hamburger-menu-item-padding;
            color: $hamburger-menu-color;
            font-size: $hamburger-menu-font-size;
        }
        a {
            @include unstyled-link(inherit);
            &:hover {
                //background-color: rgba(0, 0, 0, 0.05);
                text-decoration: underline;
            }
        }
        &:after {
            content: '';
            display: inline-block;
            padding: 3px 0;
            padding-left: 44px;
            background-color: inherit;
            position: absolute;
            right: 0;
            top: -5px;
        }

        &-dropdown {
            padding:.8rem;
            background-color: $default-grey;
            margin-bottom: .2rem;
            color: $teal-blue;
           select {
            color: $teal-blue;
            font-weight: bold;
            }
        }
    }

    &-active &-content {
        display: block;
    }
}

@include media-breakpoint-down(sm) {
    .hamburger-menu {
        right: 0;
    &-container {
        padding-right: .1rem;
        padding-left: 0;
    }
    }
}