// Root sass stylesheet for the whole application.

@import "./assets/fonts/fonts.scss";

// Combine the global styles
@import "./styles/bootstrap.scss";

// Import the styles for each individual component
@import "./styles/index.scss";
@import "./components/common/site-language.scss";
@import "./components/header/header.scss";
@import "./components/footer/footer.scss";
@import "./styles/modal.scss";
@import "./styles/reuseDialogs.scss";
@import "./pages/dashboard.scss";
@import "./components/session-timeout-warning/sessionTimeoutWarningModal.scss";
@import "./components/redirect-warning/redirectWarningModal";
@import "./components/common/loading/loading.scss";
@import "./components/common/step-progress-bar/step-progress-bar.scss";
@import "./components/common/environment-console/environment-console.scss";
@import "./styles/rtl/rtl.scss";
@import "./styles/common/resize";
@import "./components/session-timeout-page/sessionTimeoutContainer.scss";
@import "./components/dynamic-feedback-overlay/dynamicFeedbackOverlay";
@import "./components/common/error-page/error-page";
@import "./components/proctoring/assessmentPlayer.scss";

body {
    font-family: ProximaNova;
}

.bodyArial * {
    font-family: Arial !important;
}

@media (prefers-reduced-motion: no-preference) {
    html {
        scroll-behavior: smooth;
        scroll-padding-top: 8rem;
        scroll-padding-bottom: 8rem;
    }
}

*:focus {
    scroll-margin-top: 8rem;
    scroll-margin-bottom: 8rem;
}
